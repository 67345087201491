.App {
    text-align: center;
    background-color: #F8FAFB;
    padding: 5px;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  /* background-color: #282c34; */
  /* background-color: #FFFFFF; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.zoom {
    transition: transform .2s; /* Animation */
    cursor: pointer;
}

    .zoom:hover {
        transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
        
    }
